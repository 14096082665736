<template>
  <div class="gestion-condition-reglement">
    <div class="ligne-box-setting-header"></div>
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p" style="width: 100%">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style table-gestion-condition-reglement-style"
          :items="ConditionPaiement"
          :fields="fields"
          bordered
          sticky-header
          hover
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(name)="data" class="nomCategorieSize">
            {{ data.item.name }}
          </template>
          <template v-slot:cell(value)="data" class="nomCategorieSize">
            {{ data.item.value }}
          </template>
          <template v-slot:cell(fin_du_mois)="data" class="nomCategorieSize">
            {{ data.item.fin_du_mois == true ? 'Oui' : 'Non' }}
          </template>
          <template v-slot:cell(Actions)="data" class="actionCategorieSize">
            <b-button
              v-if="checkPermission('GTRHTSCP')"
              class="button-danger-style m-2"
              size="sm"
              variant="danger"
              @click.prevent.stop="deleteCurrentType(data.item)"
            >
              <font-awesome-icon icon="trash" />
            </b-button>
            <b-button
              v-if="checkPermission('GTRHTMCP')"
              class="button-danger-style m-2"
              size="sm"
              variant="success"
              @click.prevent.stop="updateCurrentType(data.item)"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="rowCondition"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="deleteConditionPaiement"
      id="deleteConditionPaiement"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer une condition du paiement</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('deleteConditionPaiement')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form
        @submit.prevent="handleDeleteCondition"
        class="form-modal-custom-style"
      >
        <Card>
          <template v-slot:body>
            <div class="mt-4 ml-3">
              <p class="text-dark">
                Êtes-vous sur de vouloir supprimer cette condition du paiement?
              </p>
            </div>
            <div class="actionModel">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="'error' + index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style mt-1">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </template>
        </Card>
      </form>
    </b-modal>
    <b-modal
      id="updateConditionPaiement"
      ref="updateConditionPaiement"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier un condition du paiement</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('updateConditionPaiement')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleupdateConditionPaiement"
            class="form-modal-custom-style"
          >
            <b-form-group label="Nom" label-for="nom" class="input-modal-champ">
              <b-form-input
                autocomplete="off"
                id="tyype"
                v-model="conditionToUpdate.name"
              ></b-form-input>
            </b-form-group>
            <div class="messageError">
              <div v-if="errorNameCondition" class="error">
                <ul
                  v-if="Array.isArray(errorNameCondition)"
                  style="list-style-type: none"
                >
                  <li v-for="(e, index) in errorNameCondition" :key="index">
                    {{ e }}
                  </li>
                </ul>
                <div v-else>{{ errorNameCondition }}</div>
              </div>
            </div>
            <b-form-group
              label="Valeur"
              label-for="nom"
              class="input-modal-champ"
            >
              <b-form-input
                autocomplete="off"
                id="tyype"
                v-model="conditionToUpdate.value"
              ></b-form-input>
            </b-form-group>
            <div class="messageError">
              <div v-if="errorValue" class="error">
                <ul
                  v-if="Array.isArray(errorValue)"
                  style="list-style-type: none"
                >
                  <li v-for="(e, index) in errorValue" :key="index">
                    {{ e }}
                  </li>
                </ul>
                <div v-else>{{ errorValue }}</div>
              </div>
            </div>
            <div id="info-wrap">
              <div class="info-column">
                <b-form-checkbox
                  style="padding-top: 12%"
                  id="checkbox-1"
                  name="checkbox-1"
                  class="check-th-details ml-2"
                  v-model="conditionToUpdate.fin_du_mois"
                ></b-form-checkbox>
              </div>
              <div class="description-column">
                <b-form-group
                  label="Fin du mois"
                  label-for="nom"
                  class="input-modal-champ"
                >
                </b-form-group>
              </div>
            </div>
            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="errorConditionPaiement" class="error">
                  <ul
                    v-if="Array.isArray(errorConditionPaiement)"
                    style="list-style-type: none"
                  >
                    <li
                      v-for="(e, index) in errorConditionPaiement"
                      :key="index"
                    >
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ errorConditionPaiement }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Gestion-conditions-paiements',
  data() {
    return {
      delais: null,
      error: null,
      loading: false,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
      ],
      fields: [
        {
          key: 'name',
          label: 'Nom du condition',
          thStyle: { width: '30%' },
        },
        {
          key: 'value',
          label: 'Valeur',
          thStyle: { width: '30%' },
        },
        {
          key: 'fin_du_mois',
          label: 'Fin du mois',
          thStyle: { width: '30%' },
        },
        { key: 'Actions', label: 'Actions' },
      ],
      typeToDelete: null,
      typeToUpdate: null,
    };
  },
  computed: {
    ...mapGetters([
      'ConditionPaiement',
      'errorConditionPaiement',
      'LoadingCondition',
      'checkPermission',
      'rowCondition',
      'errorValue',
      'errorNameCondition',
    ]),
  },
  methods: {
    ...mapActions([
      'getAllConditionsPaiement',
      'deleteConditionPaiement',
      'updateConditionPaiement',
      'resetMutationsConditions',
    ]),
    async changePerPage() {
      this.page = 1;
      await this.getAllConditionsPaiement({
        per_page: this.perPage,
        page: this.page,
      });
    },
    async pagination(paginate) {
      this.page = paginate;
      await this.getAllConditionsPaiement({
        per_page: this.perPage,
        page: this.page,
      });
    },
    resetModal() {},
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
      this.resetMutationsConditions();
    },
    deleteCurrentType(data) {
      this.typeToDelete = data;
      this.$refs['deleteConditionPaiement'].show();
    },

    async handleDeleteCondition() {
      this.loading = true;
      const response = await this.deleteConditionPaiement(this.typeToDelete);
      if (response) {
        this.loading = false;
        this.hideModal('deleteConditionPaiement');
      } else {
        this.loading = false;
      }
    },
    updateCurrentType(data) {
      this.conditionToUpdate = { ...data };
      this.oldConditionToUpdate = data;
      this.$refs['updateConditionPaiement'].show();
    },
    async handleupdateConditionPaiement() {
      this.loading = true;
      this.conditionToUpdate.fin_du_mois =
        this.conditionToUpdate.fin_du_mois == true ? 1 : 0;
      const response = await this.updateConditionPaiement({
        conditionToUpdate: this.conditionToUpdate,
        oldConditionToUpdate: this.oldConditionToUpdate,
      });
      if (response) {
        this.loading = false;
        this.hideModal('updateConditionPaiement');
      } else {
        this.loading = false;
        this.error = this.errorReglement;
      }
    },
  },
  components: {
    Card: () => import('@/views/component/card'),
  },
  async mounted() {
    await this.getAllConditionsPaiement({
      per_page: this.perPage,
      page: this.page,
    });
  },
};
</script>

<style lang="scss" scoped>
#info-wrap {
  overflow: hidden;
}
.description-column {
  width: 76%;
  float: left;
}
.info-column {
  width: 10%;
  float: left;
}
.gestion-condition-reglement {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .ligne-box-setting-header {
    margin-top: 1%;
  }
  .table-gestion-condition-reglement-style {
    max-height: calc(100vh - 310px) !important;
    height: calc(100vh - 310px) !important;
    margin-bottom: 0px;
  }
  .body-box-setting {
    height: calc(100vh - 240px) !important;
  }
  .ligne-box-setting-header {
    margin-top: 1%;
  }
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.widthTitle {
  width: 300px;
}
.loaderPosition {
  display: flex;
  flex-direction: row;
}
</style>
